* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: var(--fontFamilyInter);
    font-size: 100%;
}

body,
html {
    background-color: #f7f7f9 !important;
}

html {
    font-size: 100%;
} /* 16px - Tablet 481 to 1024 px and Laptop 1025 to 1440 px */

@media screen and (max-width: 600px) {
    html {
        font-size: 87.5%;
    }
}

@media screen and (min-width: 1441px) {
    html {
        font-size: 112.5%;
    }
}

body {
    font-weight: var(--fontWeightRegular);
    line-height: 1.5rem;
    letter-spacing: 0.009rem;
}

h1,
h2,
h3 {
    font-family: var(--fontFamilyInter);
    font-weight: var(--fontWeightBold);
}

h1 {
    font-size: 6rem;
}

h2 {
    font-size: 3.75rem;
}

h3 {
    font-size: 3rem;
}

h4 {
    font-size: 2.5rem;
}

h5 {
    font-size: 1.5rem;
}

h6 {
    font-size: 1.25rem;
}
header {
    font-family: 'Inter', sans-serif;
    text-align: center;
}
